<template>
    <div class="configuracoes-component">
      
    </div>
  </template>
    <script>
  import store from "@/store";
  import {
    onUnmounted,
    onMounted,
    ref,
    reactive,
    computed,
    nextTick,
    watch,
    onUpdated,
  } from "@vue/composition-api";
  
  export default {
    name: "configuracoes-Component",
    components: {},
    setup() {
      onMounted(() => {});

      return {
  
      };
    },
  };
  </script>
    
  <style lang="scss">
   
  </style>